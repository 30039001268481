<template>
	<div class="outer-box" :style="{ backgroundImage: bigImg }" :class="{en:internationalKey === 'en-US'}">
		<div class="inner-box">
			<div class="inner-box_left" :style="{ backgroundImage: smallImg }"></div>
			<div class="inner-box_right">
				<div class="title">
					{{ $t('WDNPC_WEB_RESET_PASSWORD', { text: '重置密码' }) }}
				</div>
				<div class="progress">
					<span class="line1" :class="{ active: step >= 2 }"></span>
					<span class="line2" :class="{ active: step == 3 }"></span>
					<div class="outer-1" :class="{ active: step >= 1 }">
						<div class="inner">1</div>
						<span class="txt">{{ $t('WDNPC_WEB_AUTHENTICATION', { text: '身份验证' }) }}</span>
					</div>
					<div class="outer-2" :class="{ active: step >= 2 }">
						<div class="inner">2</div>
						<span class="txt">{{ $t('WDNPC_WEB_SET_A_NEW_PASSWORD', { text: '设置新密码' }) }}</span>
					</div>
					<div class="outer-3" :class="{ active: step == 3 }">
						<div class="inner">3</div>
						<span class="txt">{{ $t('WDNPC_WEB_RESET_SUCCEEDED', { text: '重置成功' }) }}</span>
					</div>
				</div>
				<div class="form">
					<template v-if="step == 1">
						<el-form ref="form" :rules="rules" :model="formData">
							<el-form-item prop="account" ref="account">
								<el-input
									class="phoneInput"
									:placeholder="$t('WDNPC_WEB_ENTER_YOUR_MOBILE_PHONE_NUMBER_EMAIL_ACCOUNT', { text: '请输入手机号/邮箱账号' })"
									v-model="formData.account"
									@keyup.enter="onSubmit"
									clearable
								>
									<el-select
                                         v-if="isI18nFlag"
										v-model="code"
                                        @change="handleSelectChange"
										slot="prepend"
										style="width: 130px"
										class="phonePrepend"
										:placeholder="$t('WDNPC_WEB_PLEASE_SELECT', { text: '请选择' })"
									>
										<el-option
											:label="internationalKey === 'zh-CN'? item.cn + ' +' + item.mobile_prefix : item.en + ' +' + item.mobile_prefix"
											:value="item.code"
											v-for="item in contryList"
											:key="item.code"
										></el-option>
									</el-select>
								</el-input>
							</el-form-item>
							<el-form-item prop="authCode" ref="authCode">
								<el-input
									:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' })"
									v-model="formData.authCode"
									@keyup.enter="onSubmit"
								>
									<template v-slot:append>
										<el-button @click="sendCode" round
											>{{ 0 >= Countdown ? $t('WDNPC_WEB_SEND_VERIFICATION_CODE', { text: '发送验证码' }) : Countdown + 's' }}
										</el-button>
									</template>
								</el-input>
							</el-form-item>
						</el-form>
					</template>
					<template v-else-if="step == 2">
						<el-form ref="form1" :rules="rules1" :model="formData1">
							<el-form-item prop="password" key="password">
								<el-input
									@blur="handleBlur"
									@focus="handleFocus(1)"
									:readonly="isReadonly1"
									autocomplete="new-password"
									:placeholder="
										$t('WDNPC_WEB_PLEASE_SET_THE_PASSWORD_OF_THE_COMBINATION', { text: '请设置8-20位，数字、大小写字母、字符组合密码' })
									"
									show-password
									type="password"
									v-model="formData1.password"
									clearable
								></el-input>
							</el-form-item>
							<el-form-item prop="rePassword" key="rePassword">
								<el-input
									@blur="handleBlur"
									@focus="handleFocus(2)"
									:readonly="isReadonly2"
									autocomplete="new-password"
									:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_THE_LOGIN_PASSWORD_AGAIN', { text: '请再次输入登录密码' })"
									show-password
									type="password"
									v-model="formData1.rePassword"
									clearable
								>
								</el-input>
							</el-form-item>
						</el-form>
					</template>
					<template v-else>
						<div class="success">
							<div class="success-txt">
								{{ $t('WDNPC_WEB_CONGRATULATIONS_ON_THE_SUCCESSFUL_PASSWORD_RESET', { text: '恭喜您，密码重置成功' }) }}！
							</div>
							<div class="tip">{{ $t('WDNPC_WEB_PLEASE_KEEP_YOUR_ACCOUNT_INFORMATION_PROPERLY', { text: '请妥善保管您的账户信息' }) }}</div>
						</div>
					</template>
				</div>
				<div class="btn">
					<el-button class="loginbtn" @click="nextStep(step)" size="small"
						>{{
							step == 1
								? $t('WDNPC_WEB_NEXT_STEP', { text: '下一步' })
								: step == 2
								? $t('WDNPC_WEB_CONFIRM_RESET', { text: '确认重置' })
								: step == 3
								? $t('WDNPC_WEB_LOG_IN', { text: '去登录' })
								: ''
						}}
					</el-button>
					<div class="forgetpsd" v-if="step != 3">
						<a class="fr" @click="$router.go(-1)" style="cursor:pointer;color: #5f6368">
							{{ $t('WDNPC_WEB_RETURN_TO_LOGIN', { text: '返回登录' }) }} >
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { isPhone, isEmail, passReg } from '@/utils/validator'
import contryList from '../../../utils/country'

export default {
	name: 'forget',
	data() {
		return {
			smallImg: '',
			bigImg: '',
			step: 1,
			Countdown: 0,
			formData: {
				account: null,
				authCode: null,
			},
			formData1: {
				password: null,
				rePassword: null,
			},
			accountType: 'phone',
			isReadonly1: true, //避免弹出密码提示框
			isReadonly2: true,
            mobile_prefix: '86',
            code:'156',
            countryList: [],
            internationalKey:'zh-CN',
            isI18nFlag:false,
            rules:{
				account: [
					{
						required: true,
						message: this.$t('WDNPC_WEB_ENTER_YOUR_MOBILE_PHONE_NUMBER_EMAIL_ACCOUNT', { text: '请输入手机号/邮箱账号' }),
						trigger: 'blur',
					},
					{
						validator: this.accountValidator,
						trigger: 'blur',
					},
				],
				authCode: [
					{
						required: true,
						message: this.$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' }),
						trigger: 'blur',
					},
					{
						min: 6,
						max: 6,
						message: this.$t('WDNPC_WEB_PLEASE_ENTER_A_SIXDIGIT_VERIFICATION_CODE', { text: '请输入六位验证码' }),
						trigger: 'blur',
					},
				],
            },
            rules1:{
				password: [
					{
						required: true,
						message: this.$t('WDNPC_WEB_THE_LOGON_PASSWORD_CANNOT_BE_EMPTY', { text: '登录密码不能为空' }),
						trigger: 'blur',
					},
					{ validator: this.passwordValidator, trigger: 'blur' },
				],
				rePassword: [
					{
						required: true,
						message: this.$t('WDNPC_WEB_THE_LOGON_PASSWORD_CANNOT_BE_EMPTY', { text: '登录密码不能为空' }),
						trigger: 'blur',
					},
				],
			}
		}
	},
	// computed: {
	// 	rules() {
	// 		return {
	// 			account: [
	// 				{
	// 					required: true,
	// 					message: this.$t('WDNPC_WEB_ENTER_YOUR_MOBILE_PHONE_NUMBER_EMAIL_ACCOUNT', { text: '请输入手机号/邮箱账号' }),
	// 					trigger: 'blur',
	// 				},
	// 				{
	// 					validator: this.accountValidator,
	// 					trigger: 'blur',
	// 				},
	// 			],
	// 			authCode: [
	// 				{
	// 					required: true,
	// 					message: this.$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' }),
	// 					trigger: 'blur',
	// 				},
	// 				{
	// 					min: 6,
	// 					max: 6,
	// 					message: this.$t('WDNPC_WEB_PLEASE_ENTER_A_SIXDIGIT_VERIFICATION_CODE', { text: '请输入六位验证码' }),
	// 					trigger: 'blur',
	// 				},
	// 			],
	// 		}
	// 	},
	// 	rules1() {
	// 		return {
	// 			password: [
	// 				{
	// 					required: true,
	// 					message: this.$t('WDNPC_WEB_THE_LOGON_PASSWORD_CANNOT_BE_EMPTY', { text: '登录密码不能为空' }),
	// 					trigger: 'blur',
	// 				},
	// 				{ validator: this.passwordValidator, trigger: 'blur' },
	// 			],
	// 			rePassword: [
	// 				{
	// 					required: true,
	// 					message: this.$t('WDNPC_WEB_THE_LOGON_PASSWORD_CANNOT_BE_EMPTY', { text: '登录密码不能为空' }),
	// 					trigger: 'blur',
	// 				},
	// 			],
	// 		}
	// 	},
	// },
	created() {
        this.contryList = contryList
        this.internationalKey = localStorage.getItem('current_language') || 'zh-CN'
        this.isI18nFlag = localStorage.getItem('is_i18n') === 'true'
		// 背景图
		if (localStorage.getItem('custom_login_background_img')) {
			this.bigImg = localStorage.getItem('custom_login_background_img')
		}
		if (localStorage.getItem('custom_login_img')) {
			this.smallImg = localStorage.getItem('custom_login_img')
		}
	},
	methods: {
        handleSelectChange(val){
            this.mobile_prefix = this.contryList.find(item => item.code === val).mobile_prefix
        },
		accountValidator(rule, value, callback) {
			if (isPhone.test(value) || isEmail.test(value)) {
				callback()
			} else {
				callback(new Error(this.$t('WDNPC_WEB_PLEASE_ENTER_THE_CORRECT_MOBILE_PHONE_NUMBER', { text: '请输入正确的手机号/邮箱账号' })))
			}
		},
		passwordValidator(rule, value, callback) {
			if (passReg.test(value)) {
				callback()
			} else {
				callback(new Error(this.$t('WDNPC_WEB_PLEASE_SET_THE_PASSWORD_OF_THE_COMBINATION', { text: '请设置8-20位，数字、大小写字母、字符组合密码' })))
			}
		},
		handleFocus(val) {
			setTimeout(() => {
				if (val === 1) {
					this.isReadonly1 = false
					this.isReadonly2 = true
				} else {
					this.isReadonly2 = false
					this.isReadonly1 = true
				}
			})
		},
		judgeAccountType() {
			if (isPhone.test(this.formData.account)) {
				this.accountType = 'phone'
			}
			if (isEmail.test(this.formData.account)) {
				this.accountType = 'mail'
			}
		},
		sendCode() {
			if (this.Countdown > 0) return
			// 校验手机号、邮箱
			this.$refs.form.validateField('account', (err) => {
				console.log(err, err === '')
				if (!err) {
					this.formData.authCode = null
					this.Countdown = 60
					this.setCountdown()
					this.judgeAccountType()
					if (this.accountType === 'phone') {
						this.$api.account_new.getPhoneAuthCode4ChangePwd({ phone: this.formData.account, areaCode: this.mobile_prefix })
					} else if (this.accountType === 'mail') {
						this.$api.account_new.getMailAuthCode4ChangePwd({ mail: this.formData.account })
					}
				}
			})
		},
		async nextStep(step) {
			if (step === 1) {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.checkAuthCode()
					} else {
						return false
					}
				})
			} else if (step === 2) {
				if (this.handleBlur()) {
					this.resetPassword()
				}
			} else {
				this.$router.replace('/login')
			}
		},
		async checkAuthCode() {
			this.judgeAccountType()
			// let account = ''
			// if (this.accountType === 'phone') {
			// 	account = 86 + ':' + this.formData.account
			// } else {
			// 	account = this.formData.account
			// }
			let params = {
				areaCode: this.mobile_prefix,
				account: this.formData.account,
				authCode: this.formData.authCode,
			}
			this.$api.account_new.checkAuthCode(params).then((res) => {
				if (res.success) {
					this.step = 2
				}
			})
		},
		async resetPassword() {
			if (this.accountType === 'phone') {
				let data = {
					areaCode: this.mobile_prefix,
					phone: this.formData.account,
					authCode: this.formData.authCode,
					newPassword: this.formData1.password,
				}
				this.$api.account_new.changePasswordByPhoneAuthCode(data).then((res) => {
					if (res.success) {
						this.step = 3
					}
				})
			} else if (this.accountType === 'mail') {
				let data = {
					mail: this.formData.account,
					authCode: this.formData.authCode,
					newPassword: this.formData1.password,
				}
				this.$api.account_new.changePasswordByMailAuthCode(data).then((res) => {
					if (res.success) {
						this.step = 3
					}
				})
			}
		},
		setCountdown() {
			this.CountdownInterval = setInterval(() => {
				this.Countdown--
				if (this.Countdown <= 0) {
					clearInterval(this.CountdownInterval)
				}
			}, 1000)
		},
		handleBlur() {
			let result = true
			if (this.formData1.password && this.formData1.rePassword) {
				if (this.formData1.password !== this.formData1.rePassword) {
					this.$message.error(this.$t('WDNPC_WEB_INCONSISTENT_PASSWORD_INPUT', { text: '两次密码输入不一致' }))
					result = false
				}
			}
			return result
		},
	},
}
</script>

<style lang="stylus" scoped src="../login/css/login.styl"></style>
<style lang="stylus" scoped src="./css/forget.styl"></style>
<style lang="stylus" scoped src="./css/forget_en.styl"></style>
