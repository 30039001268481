import { render, staticRenderFns } from "./forget.vue?vue&type=template&id=0d166fa2&scoped=true"
import script from "./forget.vue?vue&type=script&lang=js"
export * from "./forget.vue?vue&type=script&lang=js"
import style0 from "../login/css/login.styl?vue&type=style&index=0&id=0d166fa2&prod&lang=stylus&scoped=true&external"
import style1 from "./css/forget.styl?vue&type=style&index=1&id=0d166fa2&prod&lang=stylus&scoped=true&external"
import style2 from "./css/forget_en.styl?vue&type=style&index=2&id=0d166fa2&prod&lang=stylus&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d166fa2",
  null
  
)

export default component.exports